<template>
    <div class="">
        <TabHeader title="意见与反馈"></TabHeader>
        <div class="content">
            <div class="bubble">
                <p>反馈指南</p>
                <p>功能异常：页面出现白屏、无法打开、内容报错、界面错误等问题</p>
                <p>产品建议：向开发者反馈产品及服务相关优化建议</p>
                <p>投诉：反馈您任何不愉快的服务体验</p>
            </div>
            <div class="form">
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" ref="planForm">
                    <a-form-model-item label="职位类型" prop="positionType" :rules="validate({ name: 'value', msg: '请选择职位类型' })">
                        <a-radio-group v-model="form.positionType">
                            <span v-for="(item,index) in position" :key="index">
                              <a-radio :value="index" v-if="item!=0">{{item}}</a-radio>
                            </span>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="联系人">
                        <a-input v-model="form.name" placeholder="请输入联系人" />
                    </a-form-model-item>
                    <a-form-model-item label="联系电话">
                        <a-input v-model="form.phone" placeholder="请输入联系电话" />
                    </a-form-model-item>
                    <a-form-model-item label="问题类型" prop="typeId" :rules="validate({ name: 'value', msg: '请选择问题类型' })">
                        <a-select v-model="form.typeId" placeholder="请选择问题类型">
                            <a-select-option  v-for="(item,index) in feedBackGetTypesData" :key="index" :value="item.id">
                               {{item.name}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="问题描述" prop="content" :rules="validate({ name: 'value', msg: '请输入问题描述' })">
                        <a-input v-model="form.content" type="textarea" placeholder="请输入问题描述" />
                    </a-form-model-item>
                    <a-form-model-item label="问题截图">
                        <a-upload accept=".bmp,.gif,.png,.jpeg,.jpg" :beforeUpload="beforeUpload" name="image"
                            list-type="picture-card" class="avatar-uploader" :file-list="fileList" :action="action"
                            :headers="uploadHeaders" @change="handleChange">
                            <div>
                                <a-icon type="plus" />
                                <div class="ant-upload-text">上传图片</div>
                            </div>
                        </a-upload>
                    </a-form-model-item>
                    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                        <a-button type="primary" @click="onSubmit">
                            提交反馈
                        </a-button>
                    </a-form-model-item>

                </a-form-model>
            </div>

        </div>
    </div>
</template>

<script>
    import TabHeader from "@/components/TabHeader"
    import store from "@/store";
    import {feedBackGetTypes,feedBackSumbit} from "@/api/FeedBack"
    export default {
        components: {
            TabHeader
        },
        data() {
            return {
                feedBackGetTypesData:[],
                labelCol: {
                    span: 3
                },
                wrapperCol: {
                    span: 14
                },
                position:["","运营","仓库","财务","物流负责人",'其他'],
                form: {
                    positionType:"",
                    name:"",
                    phone:"",
                    typeId:"",
                    content:"",
                },
                action: process.env.VUE_APP_API_URL + "/file/upload/image",
                uploadHeaders: {
                    "device-type": "web",
                    "ansuex-user-token": store.getters.getToken,
                },
                fileList: [],

            }
        },
        methods: {
            onSubmit() {
                this.$refs.planForm.validate((valid) => {
                    if(valid){
                        let photos=[]
                        for(let i of this.fileList){
                            photos.push(i.response.data.path)
                        }
                        feedBackSumbit({
                            photos,
                            ...this.form
                        }).then(()=>{
                            this.$message.success("意见提交成功！")
                            Object.keys(this.form).forEach(ele=>{
                                this.form[ele] = ''
                            })
                            this.fileList = []
                        })
                    }else{
                        this.$message.warning("请将数据填写完整！")
                    }
                })
            },
            handleChange({
                fileList
            }) {
                this.fileList = fileList;
                console.log(this.fileList);
            },
            beforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error("上传文件大小不能超过 10MB!");
                    return false;
                }
            },
        },
        created(){
            feedBackGetTypes().then(res=>{
                this.feedBackGetTypesData = res.data
            })
        }
    }
</script>

<style lang="less" scoped>
    .content {
        padding: 15px;

        .bubble {
            background: #fbfbfb;
            color: #666666;
            font-size: 14px;
            border-radius: 14px;
            width: 40%;
            padding: 15px;
        }

        .form {
            width: 50%
        }
    }
</style>